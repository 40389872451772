<template>
	<div class="council">
		<SubHeader title="Council" subtitle="理事会・事務局" />

		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-6 mb-3">
					<h2 class="mb-4">理事会</h2>
					<table class="table table-striped">
						<thead>
							<tr>
								<th>役職</th>
								<th>氏名</th>
								<th>担当</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th scope="row">会長</th>
								<td>伊藤 輝夫</td>
								<td>&nbsp;</td>
							</tr>
							<tr>
								<th scope="row">副会長</th>
								<td>山本 貴信</td>
								<td>&nbsp;</td>
							</tr>
							<tr>
								<th scope="row">理事長</th>
								<td>角家 元二</td>
								<td>JDO評議員</td>
							</tr>
							<tr>
								<th scope="row">常任理事</th>
								<td>渡辺 国雄</td>
								<td>会計監査・広報・渉外</td>
							</tr>
							<tr>
								<th scope="row">理事</th>
								<td>福山 誠</td>
								<td>会計・事務局</td>
							</tr>
							<tr>
								<th scope="row">理事</th>
								<td>宇野 利恵子</td>
								<td>総務</td>
							</tr>
							<tr>
								<th scope="row">理事</th>
								<td>吉岡 明日香</td>
								<td>総務・広報・JDO副評議員</td>
							</tr>
							<tr>
								<th scope="row">理事</th>
								<td>田島 孝康</td>
								<td>広報・競技</td>
							</tr>
							<tr>
								<th scope="row">理事</th>
								<td>砂川 祥輝</td>
								<td>広報・競技</td>
							</tr>
							<tr>
								<th scope="row">理事</th>
								<td>下谷 直樹</td>
								<td>広報・渉外</td>
							</tr>
							<tr>
								<th scope="row">理事</th>
								<td>鈳 孝子</td>
								<td>広報・渉外</td>
							</tr>
							<tr>
								<th scope="row">理事</th>
								<td>中村 真詞</td>
								<td>広報・リーグ運営</td>
							</tr>
							<tr>
								<th scope="row">理事</th>
								<td>坂東 和紘</td>
								<td>広報・リーグ運営</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="col-lg-6">
					<h2 class="mb-4">運営事務局</h2>
					<dl class="row">
						<dt class="col-sm-3">住所</dt>
						<dd class="col-sm-9">
							〒558-0004 大阪市住吉区長居東4-2-7 長居中央ビル302
						</dd>
						<!--<dt class="col-sm-3">TEL</dt>
						<dd class="col-sm-9">06-6691-8500</dd>-->
						<dt class="col-sm-3">e-mail</dt>
						<dd class="col-sm-9">odo@festa.ocn.ne.jp</dd>
					</dl>
					<div class="alert alert-primary caution" role="alert">
						<i class="fas fa-exclamation-triangle"></i
						>&nbsp;FAX廃止につき、ご連絡はメールまたは問い合わせフォームで承っております
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SubHeader from "@/components/common/SubHeader.vue";

export default {
	name: "council",
	components: {
		SubHeader,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.5rem;
	font-weight: bold;
}
.container {
	padding: 4rem 1rem;
	text-align: left;
}
.caution {
	color: #333;
	font-weight: bold;
	background-color: #e6ee9c;
	border-color: #e6ee9c;
}
</style>
